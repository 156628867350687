import React from "react";
import AllReports from "../../components/newsReports/AllReports";
import usePrismicNewsReports from "../../hooks/usePrismicNewsReports";
import usePrismicReports from "../../hooks/usePrismicReports";
import { graphql } from "gatsby";
import Main from "../../components/layouts/Main";

const ShowAllReports = () => {
  const data = usePrismicNewsReports();
  const reports = usePrismicReports(data.reports);

  return (
    <Main>
      <AllReports reports={reports} data={data} from="all" />
    </Main>
  );
};

export default ShowAllReports;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
